import { useState, useEffect, useCallback, useMemo } from 'react'
import Button from '../../components/button'
import app_api from '../../config/api'
import Table from '../../components/tables/table'
import { classNames } from '../../helpers/classname'
import { Sidebar } from '../../components/navigation/sidebar'
import ConfirmDialog from '../../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { gradeSchema } from '../../schema'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Switch } from '@headlessui/react'
import Select from 'react-select'
import moment from 'moment'
import ConfirmationDialog from '../../components/dialog/confirmation_dialog'
import BigSlideover from '../../components/big-slideover'
import logo from '../../assets/icons/logo.png'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CurrencyInput from 'react-currency-input-field'
import { parentInvoice } from '../../components/tables/tableheader'
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline'

const invoiceStatusType = [
    {
        id: 'INVOICE ISSUE',
        name: 'Invoice Issue',
    },
    {
        id: 'AWAITING PAYMENT',
        name: 'Awaiting Payment',
    },
    {
        id: 'PAID',
        name: 'Paid',
    },
    {
        id: 'NEW',
        name: 'New',
    },

]

const initialModalState = {
    type: 'add',
    state: false,
    index: null,
    edit_id: '',
    data: {
        customerId: null,
        date: new Date(),
        dueDate: '',
        serviceId: '',
        termId: '',
        subjectId: '',
        discount: '',
        servicePayload: [],
    },
}

const ParentInvoices = () => {
    const [modal, setModal] = useState(initialModalState)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [confirmationDialog, setConfirmationDialog] = useState(false)
    const [TabData, setTabData] = useState([])
    const [location, setLocation] = useState([])
    // Pagination Start
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(15)
    const [totalPages, setTotalPages] = useState(1)
    const [count, setCount] = useState(0)
    const [searchFilter, setSearchFilter] = useState('')
    // Pagination End
    const [userRole, setUserRole] = useState()
    const [locationAddress, setLocationAddress] = useState([])
    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const [payload, setPayload] = useState([])
    const [amountPayable, setAmountPayable] = useState()
    const [invoiceFilter, setInvoiceFilter] = useState([])
    const [invoiceDueDate, setInvoiceDueDate] = useState(new Date())
    const [parent, setParent] = useState([])
    const [partPayment, setPartPayment] = useState(0)
    const [parentValue, setParentValue] = useState()
    const [studentValue, setStudentValue] = useState()
    const [invoiceData, setInvoiceData] = useState()
    const [descriptionValue, setDescriptionValue] = useState(null)
    const [discountDescription, setDiscountDescription] = useState([])
    const [editStudent, setEditStudent] = useState([])
    const [discount, setDiscount] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [wholeFormDisable, setWholeFormDisable] = useState(false)
    const [student, setStudent] = useState([])
    const [discountError, setDiscountError] = useState(false)

    const goPrev = () => {
        if (page > 0) setPage((prev) => --prev)
    }

    const goNext = () => {
        if (page < totalPages - 1) setPage((prev) => ++prev)
    }

    const onDeleteOpen = (id, index) => {
        setModal((prev) => ({ ...prev, edit_id: id, index: index }))
        setConfirmationDialog(true)
    }

    const cleanModalData = () => {
        setModal(initialModalState)
    }

    const getStudentSchedule = () => {
        let email = JSON.parse(localStorage.getItem('moldKey')).email
        let url = `family-master/schedule/${email}`
        app_api
            .get(url)
            .then((res) => {
                setLoading(false)
                setParent(res.data)
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    const getInvoiceData = () => {
        app_api.get(`crm-invoice/getInvoice/${parent.id}?page=${page}&size=${limit}&searchFilter=${searchFilter}`)
            .then((res) => res.data)
            .then((res) => {
                setTabData(res.data)
                if (res.count <= limit) setPage(0)
                setTabData(res.data);
                if (isNaN(res.count / limit)) { setTotalPages(1) } else { setTotalPages(res.count / limit) }
                setCount(res.count)
                if (res.count < 15) { setLimit(res.count) } else { setLimit(15) }
            }).catch((err) => {
                setLoading(false)
                setError(err)
            })
    }


    useEffect(() => {
        getStudentSchedule()
        getLocationData()
    }, [])

    useEffect(() => {
        getInvoiceData()
    }, [parent, limit, count, page, searchFilter])


    const getLocationData = () => {
        app_api
            .get('/location')
            .then((res) => {
                let data = res.data.filter((l) => l.archive == 0)
                setLoading(false)
                let locationId = JSON.parse(localStorage.getItem('moldKey')).locationId
                let temp = []
                if (locationId.length == 0) {
                    setLocation(data)
                } else {
                    for (let i = 0; i < data.length; i++) {
                        for (let j = 0; j < locationId.length; j++) {
                            if (data[i].id == locationId[j]) {
                                temp.push({
                                    id: data[i].id,
                                    name: data[i].name,
                                })
                            }
                        }
                    }
                    if (temp) {
                        setLocation(temp)
                    } else {
                        setLocation(data)
                    }
                }
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    const getDiscountDescription = () => {
        app_api
            .get('/discount-description')
            .then((res) => {
                let data = res.data
                setLoading(false)
                setError(null)
                setDiscountDescription(data)
            })
            .catch((err) => {
                setLoading(false)
                setError(err?.response?.data?.message || 'error getting data')
            })
    }

    const getStudentData = (id) => {
        app_api
            .get(`/family-child/${id}`)
            .then((res) => {
                let data = res.data
                let dataCondition = []
                for (let i = 0; i < data.length; i++) {
                    if (data[i].studentMaster.active != 'Active') {
                        dataCondition.push(true)
                    }
                }
                // if(dataCondition.length == data.length){
                //   toast.error('This customer does not have any Active student')
                // }
                data = data.filter(l => l.studentMaster.active == 'Active')
                const editData = res.data.filter(l => l.studentMaster.active == 'Active' || l.studentMaster.active == 'Inactive')
                setLoading(false)
                setError(null)
                setStudent(data)
                setEditStudent(editData)
            })
            .catch((err) => {
                setLoading(false)
                setError(err?.response?.data?.message || 'error getting data')
            })
    }

    const onEditOpen = async (id, index) => {
        console.log(id, index)
        let data
        app_api.get(`crm-invoice/${id}`)
            .then((res) => {
                data = res.data
                setModal((prev) => ({
                    ...prev,
                    type: 'edit',
                    edit_id: id,
                    index: index,
                    state: true,
                    data: data,
                }))
                setParentValue(data.family.id)
                getStudentData(data.family.id)
                getDiscountDescription()
                setInvoiceData(data.xero_invoice_no)
                setInvoiceDate(new Date(data.xero_invoice_date))
                setInvoiceDueDate(new Date(data.xero_invoice_due_date))
                let student
                if (data.source == "Teachwork") {
                    student = data.family.studentMaster.map((e) => { return e.id })
                } else {
                    student = data.familyInvoiceItem.map((e) => { return e.studentId })
                }
                setStudentValue(student[0])
                let payloadData = []
                payloadData = data.familyInvoiceItem.map((l) => {
                    return {
                        studentId: l.studentId,
                        service: l.service ? l.service : null,
                        quantity: l.quantity,
                        amount: l.cost,
                        serviceId: l.crmServiceMaster.id,
                        serviceDescription: l.serviceDescription,
                        invoiceData: l.xero_invoice_no
                    }
                })
                let totalAmount = 0
                for (let i = 0; i < payloadData.length; i++) {
                    totalAmount =
                        totalAmount + payloadData[i].quantity * payloadData[i].amount
                }
                let PartPayment = 0
                for (let i = 0; i < data.paymentMaster.length; i++) {
                    PartPayment = PartPayment + data.paymentMaster[i].amount
                }
                setPartPayment(PartPayment)
                setLocationAddress(data.locationId)
                setInvoiceData(data.xero_invoice_no)
                setDescriptionValue(
                    data.discountDecription
                        ? data.discountDecription.id
                        : null
                )
                setDiscount(data.discount)
                setTotalAmount(totalAmount)
                let Tempdicount = data.discount
                if (totalAmount >= Tempdicount) {
                    setDiscountError(false)
                    totalAmount = Tempdicount ? totalAmount - Tempdicount : totalAmount
                } else {
                    setDiscountError(true)
                }
                setAmountPayable(parseInt(data.xero_invoice_balance))
                setPayload(payloadData)
            })
    }

    const calculateDiscount = (value) => {
        if (value != null && value >= 0) {
            let data = Array.from(payload)
            let totalAmount = 0
            for (let i = 0; i < data.length; i++) {
                totalAmount = totalAmount + data[i].quantity * data[i].amount
            }
            if (totalAmount >= value) {
                setDiscountError(false)
                totalAmount = value ? totalAmount - value : totalAmount
            } else {
                setDiscountError(true)
                totalAmount = totalAmount
            }
            // totalAmount = totalAmount + parseInt(charges)
            setAmountPayable(totalAmount)
            setDiscount(value)
            setPayload(data)
        } else {
            value = 0
            let data = Array.from(payload)
            let totalAmount = 0
            for (let i = 0; i < data.length; i++) {
                totalAmount = totalAmount + data[i].quantity * data[i].amount
            }
            if (totalAmount > value) {
                setDiscountError(false)
                totalAmount = value ? totalAmount - value : totalAmount
            } else {
                setDiscountError(true)
                totalAmount = totalAmount
            }
            setDescriptionValue(null)
            // totalAmount = totalAmount + parseInt(charges)
            setAmountPayable(totalAmount)
            setDiscount(value)
            setPayload(data)
        }
    }

    const renderModal = () => {
        const { type, state, edit_id, data } = modal
        return (
            <Formik
                initialValues={initialModalState.data}
                validationSchema={null}
                enableReinitialize
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    let body = {
                        amount: amountPayable,
                        updatedBy: JSON.parse(localStorage.getItem('moldKey')).email,
                        payload: payload
                    }
                    app_api
                        .patch(`teacher-invoice/updateinvoice/${edit_id}`, body)
                        .then((res) => {
                            toast.success('Successfully updated invoice')
                            cleanModalData()
                        }).catch((err) => {
                            toast.error('Failed to update invoice')
                            cleanModalData()
                        })
                }}
            >
                {({
                    handleBlur,
                    Formik,
                    handleChange,
                    handleSubmit,
                    setValues,
                    values,
                    touched,
                    isValid,
                    isSubmitting,
                    errors,
                }) => (
                    <BigSlideover
                        open={state}
                        setOpen={() => {
                            setModal((prev) => ({ ...prev, modalState: false }))
                            cleanModalData()
                        }}
                        title={type === 'add' ? 'Create Invoice' : `${invoiceData}`}
                        handleClick={''}
                        data={modal.data}
                        resendInvoice={null}
                    >
                        <form onSubmit={handleSubmit} noValidate>
                            <div className="-mt-6 text-left">
                                <div className="flex justify-between items-center space-x-2">
                                    <div className="w-1/4">
                                        <div className="flex ">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Customer Name
                                            </label>
                                            <span className="text-red-700 ml-1">*</span>
                                        </div>
                                        <div className="">
                                            <input
                                                className="text-left block w-full h-10 px-2 appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                name="customerId"
                                                id="customerId"
                                                loadingMessage="Getting Customer..."
                                                placeholder="Select a customer..."
                                                value={modal?.data?.family?.firstName + ' ' + modal?.data?.family?.lastName}
                                                onBlur={handleBlur}
                                                disabled={type == 'edit' || wholeFormDisable}
                                                styles={{
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-1/4 ">
                                        <div className="flex ">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Student Name
                                            </label>
                                            <span className="text-red-700 ml-1">*</span>
                                        </div>
                                        <div className="">
                                            <Select
                                                className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                isSearchable
                                                loadingMessage="Getting Student..."
                                                placeholder="Select a student..."
                                                value={
                                                    studentValue
                                                        ? student.map((l) => ({
                                                            value: student.find(
                                                                (e) => e.studentMaster.id == studentValue
                                                            )?.studentMaster?.id,
                                                            label: student.find(
                                                                (e) => e.studentMaster.id == studentValue
                                                            )?.studentMaster?.studentsFirstName,
                                                        }))
                                                        : null
                                                }
                                                options={student?.map((l) => ({
                                                    ...l,
                                                    label: l.studentMaster.studentsFirstName,
                                                    value: l.studentMaster?.id,
                                                }))}
                                                isDisabled={type == 'edit' ? true : !parentValue}
                                                onChange={(option) => {
                                                    setStudentValue(option.value)
                                                    setError(false)
                                                }}
                                                onBlur={handleBlur}
                                                menuPlacement="auto"
                                                maxMenuHeight={110}
                                                styles={{
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-2/4 mt-4 flex flex-col justify-end">
                                        <div className="flex justify-end">
                                            <div className="w-2/6">
                                                <div className="">
                                                    <label className="text-sm font-medium text-gray-700">
                                                        Select Location
                                                    </label>
                                                    <span className="text-red-700 ml-1">*</span>
                                                </div>
                                                <Select
                                                    className="text-left w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-white shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                    name="locationAddress"
                                                    id="locationAddress"
                                                    isSearchable
                                                    loadingMessage="Getting Location..."
                                                    placeholder="Select a location..."
                                                    value={
                                                        locationAddress
                                                            ? location?.map((l) => ({
                                                                value: location?.find(
                                                                    (e) => e.id == locationAddress
                                                                )?.id,
                                                                label: location?.find(
                                                                    (e) => e.id == locationAddress
                                                                )?.name,
                                                            }))
                                                            : null
                                                    }
                                                    isDisabled={true}
                                                    options={location?.map((l) => ({
                                                        ...l,
                                                        label: l.name,
                                                        value: l.id,
                                                    }))}
                                                    onChange={(option) => {
                                                        setLocationAddress(option.value)
                                                    }}
                                                    onBlur={handleBlur}
                                                    menuPlacement="auto"
                                                    maxMenuHeight={110}
                                                    styles={{
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            fontWeight: 'bold',
                                                            fontSize: '16px',
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex justify-end mt-1">
                                            <p className="w-2/6 font-semibold text-sm">
                                                {modal.data?.location?.address}
                                                <br />
                                                {modal.data?.location?.city}{' '}
                                                {modal.data?.location?.state}{' '}
                                                {modal.data?.location?.postCode}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-1/4 h-20 flex justify-center items-center mt-3">
                                        <img className="w-48" src={logo} alt="Your Company" />
                                    </div>
                                </div>
                                {parentValue ? (
                                    <>
                                        <div className="w-1/4 mt-1 mb-3 font-semibold text-sm">
                                            {modal?.data?.family?.address1}
                                            <br />
                                            <span>
                                                {modal?.data?.family?.city}{' '}
                                                {modal?.data?.family?.parentState}{' '}
                                                {modal?.data?.family?.postCode}
                                            </span>
                                            <br />
                                            <span>
                                                {modal?.data?.family?.country?.slice(0)
                                                    .toUpperCase()}
                                            </span>
                                        </div>
                                    </>
                                ) : null}
                                {/* {touched.category && (
                <p className="text-red-700 error_msg">{errors.category}</p>
              )} */}

                                <div className="flex items-center text-left">
                                    <div className="w-1/4">
                                        <div className="flex">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Invoice Date
                                            </label>
                                            <span className="text-red-700 ml-1">*</span>
                                        </div>
                                        <DatePicker
                                            className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                                            name="date"
                                            dateFormat="dd-MM-yyyy"
                                            id="date"
                                            selected={invoiceDate}
                                            disabled={true}
                                            isClearable={false}
                                            onChange={(date) => {
                                                setValues((prev) => ({
                                                    ...prev,
                                                    date: date,
                                                }))
                                            }}
                                            placeholderText="Date"
                                        />
                                    </div>

                                    <div className="w-1/4 ml-4">
                                        <div className="flex">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Due Date
                                            </label>
                                            <span className="text-red-700 ml-1">*</span>
                                        </div>
                                        <DatePicker
                                            className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                                            name="invoiceDueDate"
                                            dateFormat="dd-MM-yyyy"
                                            id="invoiceDueDate"
                                            selected={invoiceDueDate}
                                            disabled={type == 'edit' ? true : false}
                                            onChange={(date) => {
                                                setValues((prev) => ({
                                                    ...prev,
                                                    dueDate: date,
                                                }))
                                                setInvoiceDueDate(date)
                                            }}
                                            minDate={new Date()}
                                            isClearable={type == 'edit' ? false : true}
                                            placeholderText="Due Date"
                                        />
                                        {touched.categoryType && (
                                            <p className="text-red-700 error_msg">
                                                {errors.categoryType}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Spacer */}
                            <div className="mt-4"></div>
                            {/* Spacer */}
                            {modal.data.source != 'Teachwork' && (
                                <div className="flex items-center bg-stone-300 h-12 rounded-t-md">
                                    <div className="w-1/4 ml-4 text-black font-semibold text-md">
                                        <span>Type of Classes</span>
                                    </div>
                                    {/* <div className="w-2/4 ml-4 text-black font-semibold text-md">
                  <span>Description</span>
                </div> */}
                                    <div className="w-1/4 ml-4 text-black font-semibold text-md text-center">
                                        <span>Number of Session</span>
                                    </div>
                                    <div className="w-1/4 ml-4 text-black font-semibold text-md text-center">
                                        <span>Unit Price</span>
                                    </div>
                                    <div className="w-1/4 mr-4 text-black font-semibold text-md text-center">
                                        <span>Amount</span>
                                    </div>
                                </div>)}
                            {payload != null && payload.length > 0 ? (
                                <>
                                    <div className="max-h-40 overflow-auto bg-slate-50 rounded-b-md shadow-md">
                                        {payload.map((item, index) => (
                                            <>
                                                <div className="flex items-center h-10">
                                                    <div className="w-1/4 ml-4 text-black text-sm">
                                                        <span>{item.service}</span>
                                                    </div>
                                                    <div className="w-1/4 ml-4 text-black text-sm">
                                                        {/* <span>{item.quantity}</span> */}
                                                        <input
                                                            type="number"
                                                            name="quantity"
                                                            min={1}
                                                            value={item?.quantity ? item?.quantity : 10}
                                                            disabled={wholeFormDisable}
                                                            onBlur={handleBlur}
                                                            className="w-full text-center appearance-none sm:text-sm px-2 py-3 focus:bg-none focus:outline-none focus:ring-none bg-slate-50"
                                                        />
                                                    </div>
                                                    <div className="w-1/4 ml-4 text-black text-sm text-center">
                                                        <CurrencyInput
                                                            id="amount"
                                                            name="amount"
                                                            decimalsLimit={2}
                                                            value={item?.amount}
                                                            className="w-full text-center appearance-none sm:text-sm px-2 py-3 focus:bg-none focus:outline-none focus:ring-none bg-slate-50"
                                                            prefix="$"
                                                        />
                                                    </div>
                                                    <div className="w-1/4 mr-4 text-black text-sm text-center">
                                                        <span>$ {item.amount * item.quantity}</span>
                                                    </div>
                                                </div>
                                                <hr className="text-slate-400"></hr>
                                            </>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <>
                                    {modal.data.source == 'Teachwork' && modal.data.xero_invoice_balance != 0 && (
                                        <>
                                            <div className=" h-10 bg-orange-200 rounded-lg items-center ">
                                                <div className="ml-4 text-black text-lg flex justify-center items-center place-content-center text-center ">
                                                    <div className='text-center mt-2'>
                                                        <InformationCircleIcon className='w-6 text-red-400 ' />
                                                    </div>
                                                    <div className='text-center mt-2'>
                                                        <span className="">This invoice was created in Teachworks. You can only register a payment here.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {modal.data.source == 'Teachwork' && modal.data.xero_invoice_balance == 0 && (
                                        <>
                                            <div className=" h-10 bg-green-200 rounded-lg items-center ">
                                                <div className="ml-4 text-black text-lg flex justify-center items-center place-content-center text-center ">
                                                    <div className='text-center mt-2'>
                                                        <CheckCircleIcon className='w-6 text-green-800 ' />
                                                    </div>
                                                    <div className='text-center mt-2'>
                                                        <span className="">PAID</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                            <div className='flex justify-between items-center mt-14 mb-4'>
                                <div className="flex">
                                    <div className="left-auto">
                                        <p className="text-semibold text-md text-gray-500">
                                            Terms and Condition
                                        </p>
                                        <p className="text-sm text-gray-400">
                                            All invoices must be paid within 7 days of issue. <br />
                                            You can pay by credit card by clicking on the link provided
                                            in the invoice. <br />
                                            Please note, payment done by Credit Card will incur a
                                            service charge by the provider.
                                            <br />
                                            To avoid the surcharge, you may pay us directly into our
                                            account or pay by cash. <br />
                                            Our account details are given below. <br />
                                            <b>
                                                <u>
                                                    Please make sure that you put the invoice number in the
                                                    reference when doing a bank transfer
                                                </u>
                                            </b>
                                            .
                                        </p>
                                        <br></br>
                                        {locationAddress && (
                                            <>
                                                <p className="text-sm text-gray-500 mb-4">
                                                    Account Name:{' '}
                                                    {modal.data?.location?.accountName}{' '}
                                                    <br />
                                                    BSB:{' '}
                                                    {modal.data?.location?.BSB}{' '}
                                                    <br />
                                                    Account Number:{' '}
                                                    {modal.data?.location?.accountNumber}{' '}
                                                    <br />
                                                </p>
                                            </>
                                        )}
                                    </div>
                                    {/* {!wholeFormDisable && (
                  <> */}
                                    {/* </>
                )} */}
                                </div>
                                <div className='w-full'>
                                    {payload.length > 0 && (
                                        <div className="flex justify-end space-x-10">
                                            <div className="bg-gray-200 rounded-lg shadow border mt-4">
                                                <div className="flex justify-between px-2 text-sm font-mono text-gray-700 mt-2 space-x-10"   >
                                                    <p>Cart Subtotal:</p>
                                                    <p className="pr-2">${totalAmount?.toFixed(2)}</p>
                                                </div>
                                                {type == 'edit' && (
                                                    <div className="flex justify-between px-2 text-sm font-mono text-gray-700 mt-4">
                                                        <p>Amount Paid:</p>
                                                        <p className="pr-2">${partPayment?.toFixed(2)}</p>
                                                    </div>
                                                )}
                                                {!modal.data.familyInvoiceItem?.map(l => l.service).includes('Admin Charge') && (
                                                    <>
                                                        <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 pt-3">
                                                            <p>Discount:</p>
                                                            <CurrencyInput
                                                                decimalsLimit={2}
                                                                value={discount}
                                                                disabled={wholeFormDisable}
                                                                maxLength={totalAmount.toString().length}
                                                                className="block w-20 bg-gray-200 appearance-none rounded-md py-2 text-right px-2"
                                                                prefix="$"
                                                                onValueChange={(value) => {
                                                                    calculateDiscount(value)
                                                                }}
                                                            />
                                                        </div>
                                                        {discountError && (
                                                            <>
                                                                <p className="text-sm text-red-600 text-right">
                                                                    Discount is more then Total Payable Amount
                                                                </p>
                                                            </>
                                                        )}
                                                        <div className="flex justify-between bg-gray-200 items-center px-2 text-sm font-mono text-gray-700 pt-3">
                                                            <p className="">Discount Description:</p>
                                                            <Select
                                                                className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                isSearchable
                                                                isDisabled={wholeFormDisable || discount == 0}
                                                                loadingMessage="Getting Discount Description..."
                                                                placeholder="Description"
                                                                value={
                                                                    descriptionValue
                                                                        ? {
                                                                            value: discountDescription?.find(
                                                                                (l) => l.id == descriptionValue
                                                                            )?.id,
                                                                            label: discountDescription?.find(
                                                                                (l) => l.id == descriptionValue
                                                                            )?.description,
                                                                        }
                                                                        : null
                                                                }
                                                                options={discountDescription?.map((l) => ({
                                                                    ...l,
                                                                    label: l.description,
                                                                    value: l.id,
                                                                }))}
                                                                onChange={(option) => {
                                                                    setDescriptionValue(option.value)
                                                                }}
                                                                onBlur={handleBlur}
                                                                menuPlacement="auto"
                                                                maxMenuHeight={110}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                <hr class="h-px my-2 bg-black border-0 w-full px-2" />
                                                <div className="flex justify-between px-2 text-sm font-mono text-gray-700 pt-1 mb-2">
                                                    <p>Total Amount Payable:</p>
                                                    <p
                                                        className={classNames(
                                                            !modal.data.familyInvoiceItem?.map(l => l.service).includes('Admin Charge')
                                                                ? 'ml-0'
                                                                : 'ml-20', 'pr-2'
                                                        )}
                                                    >${amountPayable?.toFixed(2)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {modal.data.source == 'Teachwork' && (
                                        <div className="flex justify-end">
                                            <div className="bg-gray-200 rounded-lg shadow border mt-4">
                                                <div className="flex justify-between px-2 text-sm font-mono text-gray-700 mt-2">
                                                    <p>Cart Subtotal:</p>
                                                    <p className="pr-2">${modal.data.amountPayable?.toFixed(2)}</p>
                                                </div>
                                                <hr class="h-px my-2 mx-2 bg-black border-0" />
                                                <div className="flex justify-between px-2 text-sm font-mono text-gray-700 pt-1 mb-2">
                                                    <p>Total Amount Payable:</p>
                                                    <p>${amountPayable?.toFixed(2)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* </div> */}
                        </form>
                    </BigSlideover>
                )}
            </Formik>
        )
    }


    return (
        <Sidebar>
           
            {renderModal()}
            <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-3xl font-semibold text-gray-900">My Invoices</h1>
                        <p className="my-2 text-sm text-gray-700">
                            A list of all the my invoices.
                        </p>
                    </div>
                    <div className="sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                {loading ? (
                    <div className="flex items-center justify-center">
                        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) :
                    <>
                        <Table
                            progPage={page}
                            totalCount={count}
                            columns={parentInvoice({ onEditOpen })}
                            data={TabData}
                            setSearchFilter={setSearchFilter}
                        />
                        <nav
                            className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                            aria-label="Pagination"
                        >
                            <div>
                                <p className="text-sm text-gray-700">
                                    Showing{' '}
                                    <select
                                        onChange={(e) => {
                                            setLimit(e.target.value)
                                            setPage(0)
                                        }}
                                        value={limit}
                                    >
                                        <option value={count}>{count}</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                    </select>{' '}
                                    of <span className="font-medium">{count}</span> results
                                </p>
                            </div>
                            <div className="flex items-center md:justify-end">
                                <span
                                    onClick={goPrev}
                                    className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                                >
                                    Previous
                                </span>
                                <span className="text-sm">
                                    Page{' '}
                                    <input
                                        className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                                        type="number"
                                        value={page + 1}
                                        onChange={(e) => setPage(e.target.value - 1)}
                                        max={totalPages}
                                        min="1"
                                    />
                                    <span className='ml-2'></span><span className='ml-2'></span>/ {Math.ceil(totalPages)}
                                </span>
                                <span
                                    onClick={goNext}
                                    className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                                >
                                    Next
                                </span>
                            </div>
                        </nav>
                    </>}
            </div>
            <ToastContainer
                closeButton={false}
                closeOnClick={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
                autoClose={2000}
                hideProgressBar={false} />
        </Sidebar>
    )
}

export default ParentInvoices

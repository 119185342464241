import { useState, useEffect, useCallback, useMemo } from 'react'
import Modal from '../components/modals'
import Input from '../components/input'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { classNames } from '../helpers/classname'
import { grade_master_columns } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { gradeSchema } from '../schema'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Switch } from '@headlessui/react'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { Cog8ToothIcon } from '@heroicons/react/24/outline'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    grade:''
  },
}

const GradeMaster = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [category, setCategory] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [categoryFilter, setCategoryFilter] = useState('')
  const [categoryTypes, setCategoryTypes] = useState([])
  const [enabled, setEnabled] = useState(false)
  const [enabledSub, setEnabledSub] = useState(false)
  const [TabData, setTabData] = useState([])
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  // Pagination End

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  useEffect(() => {
    getCategoryData()
  }, [page, limit, searchFilter])

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
    setEnabled(TabData[index].vce)
    setEnabledSub(TabData[index].archive)
  }

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const onDeleteCategory = () => {
    const { id, index } = modal
    app_api
      .patch(`/grade-master/${id}`, { archive: false})
      .then((res) => {
        toast.success("Successfully inactivated")
        getCategoryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        if(err.response.data.statusCode == 406){
          toast.error('Cannot delete grade master')
        }
        else{
          toast.error('Something went wrong')
        }
        setError(err.toString())
      })
  }

  const onActiveCategory = (id) => {
    app_api
      .patch(`/grade-master/${id}`, { archive: true})
      .then((res) => {
        toast.success("Successfully activated")
        getCategoryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }

  const getCategoryData = () => {
    let url = `/grade-master/all?page=${page}&size=${limit}&searchFilter=${searchFilter}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setLoading(false)
        setError(null)
        setCategory(res.data)
        setTabData(res.data)
        if(res.count <= limit) setPage(0)
        if (res.count < 15) { setLimit(res.count) } else { setLimit(15) }
        if(isNaN(res.count / limit)){setTotalPages(1)}else{setTotalPages(res.count / limit)}
        setCount(res.count)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getCategoryData()
  }, [page , limit, searchFilter])



  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    const { grade } = data
    return (
      <Formik
        initialValues={data}
        validationSchema={gradeSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) =>
        {
          if (type === 'add') {
            app_api
              .post('/grade-master', { ...values, vce: enabled,archive:enabledSub})
              .then((res) => {
                toast.success("Successfully Added Grade")
                cleanModalData()
                getCategoryData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                if (err.response.status == 424) {
                  toast.error('Duplicate Entry')
                } else {
                  toast.error('Something Went Wrong')
                }
                cleanModalData()
                setSubmitting(false)
              })
          } else {
            app_api
              .patch(`/grade-master/${edit_id}`, { ...values, vce: enabled,archive:enabledSub})
              .then((res) => {
                getCategoryData()
                cleanModalData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {})
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          setFieldTouched,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            title={type === 'add' ? 'Add Grade' : 'Edit Grade'}
            open={state}
            setOpen={() => cleanModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Grade</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  name="grade"
                  label="Grade"
                  value={values.grade}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Grade Name..."
                  onFocus={()=>setFieldTouched('grade',false)}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.grade && (
                  <p className="text-red-700 error_msg">{errors.grade}</p>
                )}
                <div className='mt-4'></div>
                <Switch.Group
                  as="div"
                  className="flex items-center justify-between"
                >
                  <span className="flex flex-grow flex-col">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium text-gray-900"
                      passive
                    >
                      Grade a VCE?
                    </Switch.Label>
                  </span>
                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={classNames(
                      enabled ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 toggleButton'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>
                <div className='mt-4'>
                <Switch.Group
                  as="div"
                  className="flex items-center justify-between"
                >
                  <span className="flex flex-grow flex-col">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium text-gray-900"
                      passive
                    >
                      Grade Status
                    </Switch.Label>
                  </span>
                  <Switch
                    checked={enabledSub}
                    onChange={setEnabledSub}
                    className={classNames(
                      enabledSub ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 toggleButton'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabledSub ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                      ? 'Add Grade'
                      : 'Update Grade'}
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
        confirmationButtonText="Inactive"
        description = 'Do you really want to inactivate this data?'
      />
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Grade</h1>
            <p className="my-2 text-sm text-gray-700">
              A list of all the grade.
            </p>
            <nav className="flex mt-4 " aria-label="Breadcrumb">
                <ol role="list" className="flex space-x-4 rounded-md bg-white px-6 shadow">
                  <li className="flex">
                    <div className="flex items-center">
                      <Link to="/setting" className="text-gray-400 hover:text-gray-500 flex items-center gap-2">
                        <Cog8ToothIcon className="h-5 w-5 flex-shrink-0 " aria-hidden="true" />
                        <span className="">Setting</span>
                      </Link>
                    </div>
                  </li>
                    <li className="flex">
                      <div className="flex items-center">
                        <svg
                          className="h-full w-6 flex-shrink-0 text-gray-200"
                          viewBox="0 0 24 44"
                          preserveAspectRatio="none"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <Link
                          to={'/setting/grade-master'}
                          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                          aria-current='page'
                        >
                          Grades
                        </Link>
                      </div>
                    </li>
                </ol>
              </nav>
          </div>
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <Button
              onClick={() =>
                setModal((prev) => ({ ...prev, state: true, type: 'add' }))
              }
              className="flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mt-0 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add
            </Button>
          </div>
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) :
          <>
          <Table
          progPage={page}
          totalCount={count}
          columns={grade_master_columns({ onEditOpen, onDeleteOpen,onActiveCategory })}
          data={TabData}
          setSearchFilter={setSearchFilter}
        />
            <nav
        className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div>
          <p className="text-sm text-gray-700">
            Showing{' '}
            <select
              onChange={(e) => {
                setLimit(e.target.value)
                setPage(0)
              }}
              value={limit}
            >
              <option value={count}>{count}</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
            </select>{' '}
            of <span className="font-medium">{count}</span> results
          </p>
        </div>
        <div className="flex items-center md:justify-end">
          <span
            onClick={goPrev}
            className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
          >
            Previous
          </span>
          <span className="text-sm">
            Page{' '}
            <input
              className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
              type="number"
              value={page + 1}
              onChange={(e) => setPage(e.target.value - 1)}
              max={totalPages}
              min="1"
            />
            <span className='ml-2'></span><span className='ml-2'></span>/ {Math.ceil(totalPages)}
          </span>
          <span
            onClick={goNext}
            className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
          >
            Next
          </span>
        </div>
      </nav>
      </>}
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </Sidebar>
  )
}

export default GradeMaster

import { useState, useEffect, useCallback, useMemo } from 'react'
import Modal from '../components/modals'
import Input from '../components/input'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { service_columns } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmationDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { servicesTypeSchema, servicesTypeExamSchema, servicesTypeClassesSchema } from '../schema'
import Select from 'react-select'
import CurrencyInput from 'react-currency-input-field';
import HalfGeneralSlideover from '../components/half-general-slideover'
import { toast } from 'react-toastify'

const initialModalState = {
  type: 'add',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    serviceType: '',
    cost: '',
    exam_id: '',
    description: '',
    grade: '',
    subject: '',
    sessionType: ''
  },
}

const customStyles = {
  control: base => ({
    ...base,
    height: 35,
    minHeight: 15
  })
};

const serviceTypeOptions = [
  {
    id: "exam",
    name: "Exam",
  },
  {
    id: "classes",
    name: "Classes",
  }
];

const groupSizeOptions = [
  {
    id: "individual",
    name: "Individual",
  },
  {
    id: "group",
    name: "Group",
  }
];

const Services = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [service, setService] = useState([])
  const [eLoading, setELoading] = useState(true)
  const [eError, setEError] = useState(null)
  const [exam, setExam] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [examFilter, setExamFilter] = useState('')
  const [costFilter, setCostFilter] = useState('')
  const [cost, setCost] = useState([])
  const [grade, setGrade] = useState([])
  const [subject, setSubject] = useState([])
  const [sessionType, setSessionType] = useState([])
  const [showcontact, setShowContact] = useState(false)
  const [showBatch, setShowBatch] = useState(true)
  const [selectGrade, setSelectGrade] = useState()
  const [TabData, setTabData] = useState([])
  const [pagingData, setPagingData]= useState({
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: "",
    sort: {
      order: "",
      key: "",
    },
  })

  useEffect(() => {
    setPagingData({
      ...pagingData,
      total: TabData.length
    })
  },[TabData])

  const renderTable = useCallback(() => {
    return (
      <>
        <Table
          onPaginationChange={onPaginationChange}
          pagingData={tableData}
          columns={service_columns({ onEditOpen, onDeleteOpen })}
          data={
            TabData
          }
          onEditClose={cleanModalData}
        />
      </>
    )
  }, [TabData])

  const { pageIndex, pageSize, sort, query, total } = pagingData;

  const tableData = useMemo(
    () => ({ pageIndex, pageSize, sort, query, total }),
    [pageIndex, pageSize, sort, query, total]
  );

  const onPaginationChange = (page) => {
    let newService = [...service]
    setTabData(newService.slice((page-1) * pageSize))
    const newTableData = {...tableData};
    newTableData.pageIndex = page;
    setPagingData(newTableData)
  };

  useEffect(() => {
    if(pageIndex != 1){
      const newService = [...service]
      setTabData(newService.slice((pageIndex - 1) * pageSize))
    }
  }, [service])

  let serviceTypeChecker = ''

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: service[index],
    }))
  }

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const onNewContactChange = (e) => {
    setShowContact(e.target.value === 'true')
    if (e.target.value === 'true') {
      setModal((prev) => ({
        ...prev,
        data: {
          ...prev.data,
        },
      }))
    }
  }

  const onDeleteService = () => {
    const { id, index } = modal
    app_api
      .patch(`/service/archive/${id}`,{archive: 1})
      .then((res) => {
        getServiceData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }

  const getServiceData = () => {
    app_api
      .get('/service')
      .then((res) => {
        let data = res.data.filter(l => l.archive == 0)
        let temp_cost = [
          ...new Set(
            data.map((l) =>
            {
              if (l.cost == null)
              {
                return 'No Cost'
              } else
              {
                return l.cost
              }
            })
          ),
        ]
        setCost(temp_cost)
        setLoading(false)
        setError(null)
        setService(data)
        setTabData(data)
        setPagingData({
          ...pagingData,
          total: data.length
        })
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getExamData = () => {
    app_api
      .get('/exam')
      .then((res) => {
        setELoading(false)
        setEError(null)
        let data = res.data.filter((l) => l.status == 'active' && l.archive == 0)
        setExam(data)
      })
      .catch((err) => {
        setELoading(false)
        setEError(err?.response?.data?.message || 'error getting exam data')
      })
  }

  const getGradeData = () => {
    app_api
      .get('/grade-master')
      .then((res) => {
        let data = res.data
        setLoading(false)
        setError(null)
        setGrade(data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getSubjectData = () => {
    app_api
      .get('/subject-master')
      .then((res) => {
        let data = res.data
        setLoading(false)
        setError(null)
        setSubject(data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getServiceData()
    getExamData()
    getGradeData()
    getSubjectData()
  }, [])

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    data.exam_id = data.exams?.map((e) => e.examId)
    return (
      <Formik
        initialValues={data}
        validationSchema={ showcontact ? servicesTypeExamSchema : servicesTypeClassesSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) =>
        {
          if (type === 'add') {
            if(showcontact)
            {
              app_api
              .post('/service', {
                name: values.name,
                cost: parseInt(values.cost),
                exam_id: values.exam_id,
                description: values.description,
                type: 'exam'
              })
              .then((res) => {
                toast.success("Successfully Added Service")
                cleanModalData()
                getServiceData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                setError(err.toString)
              })
            }
            else{
              app_api
              .post('/service', {
                name: values.name,
                cost: parseInt(values.cost),
                grade: values.grade,
                subject: values.subject,
                sessionType: values.sessionType,
                description: values.description,
                type: 'classes'
              })
              .then((res) => {
                cleanModalData()
                getServiceData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                setError(err.toString)
              })
            }
          } else {
            app_api
              .patch(`/service/${edit_id}`, {
                name: values.name,
                cost: parseInt(values.cost),
                exam_id: values.exam_id,
                description: values.description
              })
              .then((res) => {
                let updatedServiceData = [...service]
                updatedServiceData[modal.index] = values
                setService(updatedServiceData)
                getServiceData()
                cleanModalData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {})
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneralSlideover
            title={type === 'add' ? 'Add Service' : 'Edit Service'}
            open={state}
            setOpen={() => {
              setModal((prev) => ({ ...prev, state: false }))
              cleanModalData()
            }}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left mt-4">
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Name</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  name="name"
                  label="Name"
                  value={values.name}
                  placeholder="Enter Name"
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={()=>setFieldTouched('name',false)}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.name && (
                  <p className="text-red-700 error_msg">{errors.name}</p>
                )}

                <div className="relative flex items-start mt-4">
                  <div className="flex h-5 items-center">
                    <input
                      id="new-contact"
                      aria-describedby="comments-description"
                      name="new-contact"
                      value="true"
                      type="radio"
                      // onBlur={handleBlur}
                      // onChange={handleChange}
                      // defaultChecked={values.newContact === true}
                      onClick={onNewContactChange}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="new-contact"
                      className="font-medium text-gray-700"
                    >
                      Exam
                    </label>
                  </div>
                </div>
                <div className="relative flex items-start mt-2 mb-4">
                  <div className="flex h-5 items-center">
                    <input
                      id="existing-contact"
                      aria-describedby="comments-description"
                      value="false"
                      name="new-contact"
                      type="radio"
                      // defaultChecked={values.newContact === false}
                      onClick={onNewContactChange}
                      defaultChecked
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="existing-contact"
                      className="font-medium text-gray-700"
                    >
                      Classes
                    </label>
                  </div>
                </div>



                {showcontact && (
                  <>
                <div className="mt-4">
                      <div className="flex">
                  <label
                    htmlFor="exam_id"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Exam
                  </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="exam_id"
                    id="exam_id"
                    isSearchable
                    loadingMessage="Getting Exam..."
                    placeholder="Select a Exam"
                    isMulti={true}
                    value={
                      values.exam_id
                        ? values?.exam_id?.map((l) => ({
                            value: l,
                            label: exam.find((e) => e.id == l)
                              ?.name,
                          }))
                        : null
                    }
                    options={exam.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.id,
                    }))}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        exam_id: option.map((l) => l.value),
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                    onFocus={()=>setFieldTouched('exam_id',false)}
                  />
                  {/* <select
                    id="exam_id"
                    name="exam_id"
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    value={values.exam_id}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <option value="">Select an exam</option>
                    {exam?.map((e, index) => (
                      <option key={index} value={e.id}>
                        {e?.name}
                      </option>
                    ))}
                  </select> */}
                </div>
                {touched.exam_id && (
                  <p className="text-red-700 error_msg ">{errors.exam_id}</p>
                )}
                </>)}



                {/* Classes */}


                {!showcontact && (
                  <>
                <div className="mt-4">
                      <div className='flex'>
                        <label className='block text-sm font-medium text-gray-700' >Grade</label>
                        <span className='text-red-700 ml-1'>*</span>
                      </div>

                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="grade"
                    id="grade"
                    isSearchable
                    loadingMessage="Getting Exam..."
                    placeholder="Select a Grade"
                    value={
                      values.grade
                        ? grade?.map((l) => ({
                            value: grade?.find(
                              (e) => e.grade == values.grade
                            )?.id,
                            label: grade?.find(
                              (e) => e.grade == values.grade
                            )?.grade,
                          }))
                        : null
                    }
                    options={grade.map((l) => ({
                      ...l,
                      label: l.grade,
                      value: l.id,
                    }))}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        grade: option.label,
                        vce: option.vce
                      })
                      setSelectGrade(option.vce)
                    }}
                    onFocus={()=>setFieldTouched('grade',false)}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                  />
                </div>
                {touched.grade && (
                  <p className="text-red-700 error_msg ">{errors.grade}</p>
                )}

                <div className="mt-4">
                      <div className='flex'>
                        <label className='block text-sm font-medium text-gray-700' >Subject</label>
                        <span className='text-red-700 ml-1'>*</span>
                      </div>

                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="subject"
                    id="subject"
                    isSearchable
                    loadingMessage="Getting Exam..."
                    placeholder="Select a Subject"
                    value={
                      values.subject
                        ? subject?.map((l) => ({
                            value: subject?.find((e) => e.subject == values.subject)
                              ?.id,
                            label: subject?.find((e) => e.subject == values.subject)
                              ?.subject,
                          }))
                        : null
                    }
                    options={subject.filter((l) => {
                      if (values.vce == l.vce) {
                        return true
                      } else {
                        return false
                      }
                    })
                    .map((l) => ({
                      ...l,
                      label: l.subject,
                      value: l.subject,
                    }))}
                    onChange={(option) => {
                      console.log(option)
                      setValues({
                        ...values,
                        subject: option.subject,
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                    onFocus={()=>setFieldTouched('subject',false)}
                  />
                </div>
                {touched.subject && (
                  <p className="text-red-700 error_msg ">{errors.subject}</p>
                )}
                <div className="mt-4">
                      <div className='flex'>
                        <label className='block text-sm font-medium text-gray-700' >Group Size</label>
                        <span className='text-red-700 ml-1'>*</span>
                      </div>

                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="sessionType"
                    id="sessionType"
                    isSearchable
                    loadingMessage="Getting Group Size..."
                    placeholder="Select a Group Size..."
                    value={
                      values.sessionType
                        ? groupSizeOptions?.map((l) => ({
                            value: groupSizeOptions?.find((e) => e.id == values.sessionType)
                              ?.id,
                            label: groupSizeOptions?.find((e) => e.id == values.sessionType)
                              ?.name,
                          }))
                        : null
                    }
                    options={groupSizeOptions.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.id,
                    }))}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        sessionType: option.value,
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                    onFocus={()=>setFieldTouched('sessionType',false)}
                  />
                </div>
                {touched.sessionType && (
                  <p className="text-red-700 error_msg ">{errors.sessionType}</p>
                )}
                </>)}


                <div className="mt-4"></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Cost</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>

                  <CurrencyInput
                    id="cost"
                    name="cost"
                    placeholder="Please enter cost amount"
                    decimalsLimit={2}
                    value={values.cost}
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                    prefix='$'
                    onValueChange={(value) => {
                      setValues({
                        ...values,
                        cost: value
                      })
                    }}
                    onFocus={()=>setFieldTouched('cost',false)}
                  />
                {touched.cost && (
                  <p className="text-red-700 error_msg ">{errors.cost}</p>
                )}

                <div className="mt-4"></div>
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <textarea
                    id="description"
                    placeholder="Please enter the description"
                    name="description"
                    rows={4}
                    cols={40}
                    value={values.description}
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                    onChange={(event) => {
                      setValues({
                        ...values,
                        description: event.target.value
                      })
                    }}
                    onFocus={()=>setFieldTouched('description',false)}
                  ></textarea>
                <div className="mt-3 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                      ? 'Add Service'
                      : 'Update Service'}
                  </Button>
                </div>
              </div>
            </form>
          </HalfGeneralSlideover>
        )}
      </Formik>
    )
  }

  return (
    <>
      <Sidebar>
        <ConfirmationDialog
          setOpen={setConfirmationDialog}
          open={confirmationDialog}
          onDelete={onDeleteService}
        />
        {renderModal()}
        <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-3xl font-semibold text-gray-900">Services</h1>
              <p className="my-2 text-sm text-gray-700">
                A list of all the services.
              </p>
            </div>
            <div className="sm:mt-0 sm:ml-16 sm:flex-none">
              <Button
                onClick={() =>
                  setModal((prev) => ({ ...prev, type: 'add', state: true }))
                }
                className="flex justify-center items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 mt-0 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                Add
              </Button>
            </div>
          </div>
          {/* <div className="absolute mt-5 w-7/12 flex justify-end items-center text-right right-0 mr-2">
            <div className="w-1/3 ml-1">
              <Select
                className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                name="name"
                id="name"
                isSearchable
                loadingMessage="Getting Exam..."
                placeholder="Exam"
                isClearable={true}
                options={exam.map((l) => ({
                  ...l,
                  label: l.name,
                  value: l.id,
                }))}
                onChange={(option) =>
                {
                  setExamFilter(option)
                }}
                isLoading={loading}
              />
            </div>
          </div> */}
          {/* <Table
            columns={service_columns({ onEditOpen, onDeleteOpen })}
            data={service ? service?.filter((l) =>
            {
              if (examFilter)
              {
                return l?.exams.map((l) => l?.exam.name) == examFilter.name
              } else return true
            }) : null}
            onEditClose={cleanModalData}
          /> */}
          {renderTable()}
        </div>
      </Sidebar>
    </>
  )
}

export default Services

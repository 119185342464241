import React, { useEffect, useState } from 'react'
import Button from '../components/button'
import logo from '../assets/icons/logo.png'
import { Formik } from 'formik'
import app_api from '../config/api'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ConfirmationPaymentDialog from '../components/dialog/enrolment_payment'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select'
import { classNames } from '../helpers/classname'
import Input from '../components/input'
import { enquiryFormSchema } from '../schema'
import ReCAPTCHA from 'react-google-recaptcha'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    parentFirstName: '',
    parentLastName: '',
    parentEmail: '',
    parentMobileNo: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postCode: '',
    studentsFirstName: '',
    studentsLastName: '',
    studentsEmail: '',
    studentsMobileNo: '',
    dob: '',
    grade: '',
    studentCountry: '',
    studentState: '',
    terms: false,
    preferredLocation:'',
  },
}

const contactTypeList = [
  { id: 'Phone', name: 'Phone' },
  { id: 'Email', name: 'Email' },
]

const EnrolmentPage = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [paymentData, setPaymentData] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [studentState, setStudentState] = useState([])
  const [location, setLocation] = useState([])
  const [inputList, setInputList] = useState([
    {
      studentsFirstName: '',
      studentsLastName: '',
      studentsEmail: null,
      grade: null,
      help: '',
      study_subject: '',
      groupSizeOptionsValue: '',
    },
  ])
  const [subject, setSubject] = useState([])
  const [grade, setGrade] = useState([])
  const [selectGrade, setSelectGrade] = useState()
  const [ tuition, setTuition ] = useState([])
  const [ formError, setFormError] = useState(null)
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
  const [captchaError, setCaptchaError] = useState('');
  let timeout

  useEffect(() => {
    getLocationData()
    getStateDataByBackend()
    getGradeData()
    getSubjectData()
    getTuitionData()
  }, [])

  // const handleCaptcha = () => {
  //    setIsRecaptchaChecked(!isRecaptchaChecked);
  // };

  //   const handleCaptchaError= ()=>{
  //     if (isRecaptchaChecked){
  //       setCaptchaError('False')
  //     }else{
  //       setCaptchaError(null)
  //     }
  //   }

  const getGradeData = () => {
    app_api
      .get('/grade-master')
      .then((res) => {
        let data = res.data
        setLoading(false)
        setError(null)
        setGrade(data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getTuitionData = () =>
  {
    app_api
    .get('tuition-master/all')
    .then((res) => res.data)
    .then((res) => {
      let data = res.data
      setLoading(false)
      setError(null)
      setTuition(data)
    })
    .catch((err) => {
      setLoading(false)
      setError(err?.response?.data?.message || 'error getting data')
    })
  }

  const getStateDataByBackend = () => {
    app_api
      .post('/state-city/state')
      .then((res) => {
        let data = res.data
        setStudentState(data)
        console.log(data)
        setLoading(false)
        setError(null)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const alertFunc = () => {
    window.location.href = "https://www.nrichlearning.com.au/"
  }

  const getSubjectData = () => {
    app_api
      .get('/subject-master/all')
      .then((res) => res.data)
      .then((res) => {
        let data = res.data
        setLoading(false)
        setError(null)
        setSubject(data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getLocationData = () =>
  {
    app_api
      .get('/location')
      .then((res) =>
      {
        let data = res.data.filter(l => l.archive == 0)
        setLoading(false)
        setError(null)
        setLocation(data)
      })
      .catch((err) =>
      {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const renderType = () => {
    return (
      <>
        <div className="flex flex-col">
          { inputList.map( ( x, i = 3 ) =>
          {
            return (
              <>
                <hr className="mx-3 mt-4"></hr>
                <div className="grid lg:grid-cols-4 grid-cols-1 lg:gap-4 gap-1 flex flex-wrap mt-1 text-left">
                  <div className="col-span-2 mt-2 mr-4 ml-4">
                    <label htmlFor="" className="mb-2 text-sm text-gray-700">
                      Student Name
                    </label>
                    <input
                      id="studentsFirstName"
                      placeholder="Student Name"
                      name="studentsFirstName"
                      type="text"
                      required={true}
                      autoComplete="off"
                      value={x.studentsFirstName}
                      onChange={(e) => handleInputChange(e, i)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-2 mt-2 mr-4 ml-4">
                    <label htmlFor="" className="mb-2 text-sm text-gray-700">
                      Grade
                    </label>
                    <Select
                      className="text-left w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="grade"
                      id="grade"
                      isSearchable
                      loadingMessage="Getting Grade..."
                      placeholder="Select a grade..."
                      value={
                        x.grade
                          ? grade?.map((l) => ({
                              value: grade?.find((e) => e.id == x.grade)?.id,
                              label: grade?.find((e) => e.id == x.grade)?.grade,
                            }))
                          : null
                      }
                      options={grade?.map((l) => ({
                        ...l,
                        label: l.grade,
                        value: l.id,
                      }))}
                      isDisabled={x.studentsFirstName == ''}
                      onChange={(option) => {
                        console.log(option.vce)
                        setSelectGrade(option.vce)
                        handleSelectChange(option.id, 'grade', i)
                      }}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                    />
                  </div>
                  <div className=" col-span-2 mt-2 mr-4 ml-4">
                    <label htmlFor="" className="mb-2 text-sm text-gray-700">
                      Purpose of attending tuition
                    </label>
                    <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="help"
                      id="help"
                      isSearchable
                      loadingMessage="Getting Value..."
                      placeholder="Select a purpose of attending tuition..."
                      value={
                        x.help
                          ? tuition?.map((l) => ({
                            value: tuition?.find((e) => e.tuition==x.help)
                              ?.id,
                            label: tuition?.find((e) => e.tuition==x.help)
                              ?.tuition,
                          }))
                          :null
                      }
                      isDisabled={x.studentsFirstName == ''}
                      options={tuition.map((l) => ({
                        ...l,
                        label: l.tuition,
                        value: l.id,
                      }))}
                      onChange={(option,e) =>
                      {
                        handleSelectChange(option.label,'help',i)
                      }}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                    />
                  </div>
                  <div className="col-span-2 mt-2 mr-4 ml-4">
                    <label htmlFor="" className="mb-2 text-sm text-gray-700">
                      Like to study subject
                    </label>
                    <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="study_subject"
                      id="study_subject"
                      isSearchable
                      loadingMessage="Getting Subject..."
                      placeholder="Select a subject..."
                      isDisabled={x.studentsFirstName == ''}
                      value={
                        x.study_subject
                          ? subject?.map((l) => ({
                              value: subject?.find(
                                (e) => e.subject == x.study_subject
                              )?.id,
                              label: subject?.find(
                                (e) => e.subject == x.study_subject
                              )?.subject,
                            }))
                          : null
                      }
                      options={subject?.filter((l) => {
                        if(selectGrade == l.vce){
                          return true
                        }
                        else{
                          return false
                        }
                      })
                        .map((l) => ({
                        ...l,
                        label: l.subject,
                        value: l.subject,
                      }))}
                      onChange={(option, e) => {
                        handleSelectChange(option.subject, 'study_subject', i)
                      }}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                    />
                  </div>
                </div>
                <div className="flex justify-center items-center mt-4 ml-5">
                  {inputList.length !== 1 && (
                    <button
                      type="button"
                      className="rounded-md px-3 bg-red-700 text-sm text-white"
                      onClick={() => handleRemoveClick(i)}
                    >
                      Remove
                    </button>
                  )}
                  {inputList.length - 1 === i && inputList.length < 3 &&(
                    <button
                      type="button"
                      // className="rounded-md px-3 bg-green-700 text-sm text-white ml-2"
                      className={classNames(
                        inputList.length == 1
                          ? 'ml-0'
                          : 'ml-2',
                          "rounded-md px-3 bg-indigo-800 text-sm text-white cursor-pointer"
                      )}
                      onClick={handleAddClick}
                    >
                      Click to add another child
                    </button>
                  )}
                </div>
              </>
            )
          })}
        </div>
      </>
    )
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleSelectChange = (value, name, index) => {
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleRemoveClick = (index) => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        studentsFirstName: '',
        studentsLastName: '',
        studentsEmail: '',
        grade: null,
        help: '',
        study_subject: '',
      },
    ])
  }

  return (
    <>
      <ConfirmationPaymentDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        data={paymentData}
      />
      <div className="flex min-h-screen justify-center items-center">
        <div className="lg:w-2/4 lg:block lg:mx-9 mt-2 mb-2 sm:mx-0">
          <Formik
            initialValues={initialModalState.data}
            validationSchema={enquiryFormSchema}
            onSubmit={(values, { setSubmitting, resetForm }) =>
            {
              // if (!isRecaptchaChecked) {
              //   console.log("called")
              //   handleCaptchaError()
              // }
              // else{
              //   handleCaptchaError()
                setLoading(true)
                let value = true
                if(value == true){
                  app_api
                    .post('contact/enquiry-form/Enquiry%20Form', {
                      parentEmail: values.parentEmail,
                      parentFirstName: values.parentFirstName,
                      parentLastName: values.parentLastName,
                      parentMobileNo: values.parentMobileNo.toString(),
                      addressLine1: values.addressLine1,
                      addressLine2: values.addressLine2,
                      country: 'Australia',
                      postCode: values.postCode.toString(),
                      studentPayload: inputList,
                      preferredLocation: values.preferredLocation,
                      contactType: values.contactType,
                      // couponCode: 'D3 Event'
                    })
                    .then((res) => {
                      setLoading(false)
                      toast.success("Thank you for your enquiry! We'll review it and get back to you soon. Explore our website for more information.")
                      timeout = setTimeout(alertFunc, 3000);
                    })
                    .catch((err) => {
                      toast.error(err.response.data.message)
                      setSubmitting(false)
                      setLoading(false)
                      setError(
                        err?.response?.data?.message || 'error creating contact'
                      )
                    })
                // }   
              }
            
            // {
            //   setLoading(true)
            //   let value = true
            //   // for(let i = 0; i < inputList.length; i++){
            //   //   if(inputList[i].studentsFirstName == ''){
            //   //     console.log(true)
            //   //     setFormError('student name cannot be empty')
            //   //     toast.error('Student name cannot be empty')
            //   //     setSubmitting(false)
            //   //     setLoading(false)
            //   //     value = false
            //   //   }
            //   //   else{
            //   //     setFormError(null)
            //   //     value = true
            //   //   }
            //   // }
            //   if(value == true){
            //     app_api
            //       .post('contact/enquiry-form/Enquiry%20Form', {
            //         parentEmail: values.parentEmail,
            //         parentFirstName: values.parentFirstName,
            //         parentLastName: values.parentLastName,
            //         parentMobileNo: values.parentMobileNo.toString(),
            //         addressLine1: values.addressLine1,
            //         addressLine2: values.addressLine2,
            //         country: 'Australia',
            //         postCode: values.postCode.toString(),
            //         studentPayload: inputList,
            //         preferredLocation: values.preferredLocation,
            //         contactType: values.contactType,
            //         // couponCode: 'D3 Event'
            //       })
            //       .then((res) => {
            //         setLoading(false)
            //         toast.success("Thank you for your inquiry! We'll review it and get back to you soon. Explore our website for more information.")
            //         timeout = setTimeout(alertFunc, 3000);
            //       })
            //       .catch((err) => {
            //         toast.error(err.response.data.message)
            //         setSubmitting(false)
            //         setLoading(false)
            //         setError(
            //           err?.response?.data?.message || 'error creating contact'
            //         )
            //       })
            //   }              
            // }
          }}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              setValues,
              setFieldTouched,
              values,
              errors,
              touched,
              isValid,
              setSubmitting,
              isSubmitting,
            }) => (
              <form
                className="w-full shadow rounded-lg border-2 mt-2"
                onSubmit={handleSubmit}
                noValidate={true}
              >
                <div className="col-span-4 mt-4">
                  <div className="text-xl mb-4 text-black rounded-t-lg text-center flex flex-col justify-center items-center">
                  <div className="mr-2">
                      <img src={logo} className="w-64" />
                    </div>
                    <div className="ml-6 mt-4">Expression of Interest Form</div>
                  </div>
                </div>
                <hr className="mx-6 mt-1"></hr>
                <div className="grid lg:grid-cols-4 grid-cols-1 lg:gap-4 gap-1 flex flex-wrap">
                  <div className="col-span-2 mt-4 mr-4 ml-4 sm:ml-4">
                    <div className="flex"> 
                    <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium">
                      Parent's First Name
                    </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      id="parentFirstName"
                      placeholder="Parent's First Name"
                      name="parentFirstName"
                      type="text"
                      required={true}
                      value={values.parentFirstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      onFocus={() => setFieldTouched('parentFirstName',false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.parentFirstName && (
                      <p className="text-red-700 error_msg mt-2">
                        {errors.parentFirstName}
                      </p>
                    )}
                  </div> 
                  <div className="col-span-2 mt-4 mr-4 ml-4">
                    <div className="flex">
                      <label htmlFor="selectFamily" className="mb-1 text-sm text-gray-700 font-medium"> Parent's Last Name </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      id="parentLastName"
                      placeholder="Parent Name"
                      name="parentLastName"
                      type="text"
                      required={true}
                      value={values.parentLastName}
                      onChange={handleChange}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched('parentLastName',false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.parentLastName && (
                      <p className="text-red-700 error_msg mt-2">
                        {errors.parentLastName}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2 mt-4 mr-4 ml-4">
                    <div className="flex">
                      <label htmlFor="selectFamily" className="mb-1 text-sm text-gray-700 font-medium"> Parent's Email </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      id="parentEmail"
                      placeholder="Email"
                      name="parentEmail"
                      type="email"
                      required={true}
                      value={values.parentEmail}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched('parentEmail',false)}
                   />
                    {touched.parentEmail && (
                      <p className="text-red-700 error_msg mt-2">
                        {errors.parentEmail}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2 mt-4 mr-4 ml-4">
                    <div className="flex">
                      <label htmlFor="selectFamily" className="mb-1 text-sm text-gray-700 font-medium">  Parent's Mobile No</label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      id="parentMobileNo"
                      placeholder="Mobile No"
                      name="parentMobileNo"
                      type="tel"
                      value={values.parentMobileNo}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          parentMobileNo: e.target.value,
                        })
                      }}
                      onBlur={handleBlur}
                      autoComplete="off"
                      onFocus={() => setFieldTouched('parentMobileNo',false)}
                    />
                    {touched.parentMobileNo && (
                      <p className="text-red-700 error_msg mt-2">
                        {errors.parentMobileNo}
                      </p>
                    )}
                  </div>

                  <div className="col-span-4 mt-4 mr-4 ml-4">
                    <label htmlFor="" className="mb-1 text-sm text-gray-700">
                      Choose a location
                    </label>
                    <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="preferredLocation"
                      id="preferredLocation"
                      isSearchable
                      loadingMessage="Getting location..."
                      placeholder="Select a location..."
                      value={
                        values.preferredLocation
                          ? location?.map((l) => ({
                              value: location?.find(
                                (e) => e.id == values.preferredLocation
                              )?.id,
                              label: location?.find(
                                (e) => e.id == values.preferredLocation
                              )?.name,
                            }))
                          : null
                      }
                      options={location.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          preferredLocation: option.value,
                        })
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                      onFocus={() => setFieldTouched('preferredLocation',false)}
                    />
                    {touched.preferredLocation && (
                      <p className="text-red-700 error_msg mt-2">
                        {errors.preferredLocation}
                      </p>
                    )}
                  </div>
                </div>
                {renderType()}
              {/*   <div className="mt-8 ml-3 ">
                    <ReCAPTCHA
                    // test
                      sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                      // sitekey='6LefV78nAAAAAIS07gLsbFTetEHOkpGTT2isPcBZ'
                      onChange={handleCaptcha}
                      size="normal"
                      name="captcha"
                      required={true}
                    />
                     {captchaError && (
                      <p className="text-red-700 error_msg mt-2">
                        {captchaError}
                      </p>
                    )}
                </div> */}
                <div className="submitdiv">
                  <Button
                    type="submit"
                    loading={loading}
                    className="submitBtn bg-green-700"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </>
  )
}
export default EnrolmentPage

import { useState, useEffect, useCallback, useMemo } from 'react'
import Modal from '../components/modals'
import Input from '../components/input'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { user_column } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { userSchema, adminSchema } from '../schema'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Switch } from '@headlessui/react'
import { classNames } from '../helpers/classname'
import Select from 'react-select'
import moment from 'moment'
import { FunnelIcon } from '@heroicons/react/24/outline'
import ColumnSlideOver from '../components/column_slide_over'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    email: '',
    is_active: true,
    role: '',
    locationId: [],
    status: ''
  },
}

const roleValueEdit = [{
  id: 'admin',
  name: 'Admin'
},
{
  id: 'manager',
  name: 'Manager'
},
{
  id:'teacher',
  name: 'Teacher'
},
{
  id:'parent',
  name: 'Parent'
},
{
  id:'student',
  name: 'Student'
},
]

const UserPage = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [category, setCategory] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [location, setLocation] = useState([])
  const [roleFilter, setRoleFilter] = useState('')
  const [locationFilter, setLocationFilter] = useState('')
  const [roleName, setRoleName] = useState('')
  const [TabData, setTabData] = useState([])
  const [enabled, setEnabled] = useState(false)
  const [inactiveStatusConfirmationDialog, setInactiveStatusConfirmationDialog] = useState(false)
  const [activeStatusConfirmationDialog, setActiveStatusConfirmationDialog] = useState(false)
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  // Pagination End

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }
  const [columns, setColumns] = useState([])
  const [columnFilter, setColumnsFilter] = useState([])
  const [openSlider, setOpenSlider] = useState(false)
  let columnName = 'user'
  let user_id = JSON.parse(localStorage.getItem('moldKey')).id
  let title = 'Category Columns'
  let description = 'Customize columns according to your preferences'


  const handleClick = (num) => {
    let payload = num
    app_api
      .patch(`/user-setting/${user_id}/${columnName}`, payload)
      .then((res) => {
        setOpenSlider(false)
        getColumns()
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getColumns = () => {
    app_api
      .get(`/user-setting/${user_id}/${columnName}`)
      .then((res) => {
        let data = res.data
        setLoading(false)
        setError(null)

        setColumnsFilter(data)
        let dynamic_columns = data.map((key) => {
          if (key.active == true) {
            return {
              Header: key.header == 'Centre' ? 'Location' : key.header,
              accessor: key.table
                ? key.table + '.' + key.column
                : key.column,
            }
          }
        })
        let columns = [...dynamic_columns]
        columns = columns.filter(function (element) {
          return element !== undefined
        })
        setColumns(columns)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const renderTable = useCallback(() => {
    return (
      <>
       <Table
          progPage={page}
          totalCount={count}
          columns={user_column({ onEditOpen, onDeleteOpen, onStatusInactiveOpen, onStatusActiveOpen,columns })}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
      </>
    )
  }, [TabData,columns])

  useEffect(() => {
    getLocationData()
    getColumns()
  }, [])

  useEffect(() => {
    getCategoryData()
  }, [page, limit, searchFilter])

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const onStatusInactiveOpen = (id, index) => {
    console.log(id)
    setModal((prev) => ({
      ...prev,
      edit_id: id,
      index: index,
      data: TabData[index],
    }))
    setInactiveStatusConfirmationDialog(true)
  }

  const onStatusActiveOpen = (id, index) => {
    console.log(id)
    let status = TabData[index].is_active
    console.log(status)
    let payload
    if(status){
      payload = {
        is_active: false
      }
    }
    else{
      payload = {
        is_active: true
      }
    }
    app_api
      .patch(`/users/status/${id}`, payload)
      .then((res) => {
        toast.success('Status update successfully')
        getCategoryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
    })
  }

  const onStatusChange = () => {
    const { edit_id, index } = modal
    let status = modal.data.is_active
    let payload
    if(status){
      payload = {
        is_active: false
      }
    }
    else{
      payload = {
        is_active: true
      }
    }
    app_api
      .patch(`/users/status/${edit_id}`, payload)
      .then((res) => {
        toast.success('Status update successfully')
        getCategoryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
    setEnabled(TabData[index].is_active)
  }

  const onDeleteCategory = () => {
    const { id, index } = modal
    app_api
      .delete(`/users/${id}`)
      .then((res) => {
        toast.success('Deleted Successfully')
        getCategoryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const getCategoryData = () => {
    let url = `users/all?page=${page}&size=${limit}&searchFilter=${searchFilter}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        
        setError(null)
        if(res.count <= limit) setPage(0)
        if(res.count < 15){ setLimit(res.count)}else{ setLimit(15)}
        setCategory(res.data)
        setTabData(res.data)
        if(isNaN(res.count / limit)){setTotalPages(1)}else{setTotalPages(res.count / limit)}
        setCount(res.count)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getLocationData = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let url = `location/all?&email=${email}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) =>{
        
        setError(null)
        setLocation(res.data)
      })
      .catch((err) =>
      {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getCategoryData()
    getLocationData()
  }, [])

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    const {
        name,
        email,
        role,
    } = data
    data.locationId = data.location?.map((e) => e.locationId)

    const getCustomerData = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const value = emailRegex.test(email);
      if (value) {
        app_api
          .get(`/family-master/selectemail/${email}`)
          .then((res) => {
            if (res.data != "") {
              setModal((prev) => ({
                ...prev, state: true, type: 'add', data: {
                  name: res.data.lastName + ', ' +res.data.firstName,
                  role: 'parent',
                  email: email,
                  locationId: res.data.center
                }
              }))
            } else {
              setModal((prev) => ({
                ...prev, state: true, type: 'add', data: {
                  ...prev, name: '',
                  role: 'parent',
                  email: email,
                }
              }))
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    return (
      <Formik
        initialValues={data}
        validationSchema={roleName == 'manager' ? userSchema : adminSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) =>
        {
          if (type === 'add') {
            app_api
              .post('users/location', {...values, is_active: enabled})
              .then((res) => {
                cleanModalData()
                getCategoryData()
                setSubmitting(false)
                resetForm()
                toast.success('Created Successfully')
              })
              .catch((err) => {
                if (err.response.status == 424) {
                  toast.error('Duplicate Entry')
                } else {
                  toast.error('Something Went Wrong')
                }
                cleanModalData()
                setSubmitting(false)
              })
          } else {
            app_api
              .patch(`/users/details/${edit_id}`, {...values, is_active: enabled})
              .then((res) => {
                getCategoryData()
                cleanModalData()
                setSubmitting(false)
                resetForm()
                toast.success('Updated Successfully')
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            title={type === 'add' ? 'Add User' : 'Edit User'}
            open={state}
            setOpen={() => cleanModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
              <div className="mt-4"></div>
              <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Role</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>          
                <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="role"
                  id="role"
                  isSearchable
                  loadingMessage="Getting Role..."
                  placeholder="Select a Role"
                  value={values.role ? roleValueEdit?.map((l) => ({
                    value: roleValueEdit?.find((e) => e.id == values.role)
                      ?.id,
                    label: roleValueEdit?.find((e) => e.id == values.role)
                      ?.name,
                  })) : null
                  }
                  options={roleValueEdit?.map((l) => ({
                    ...l,
                    label: l.name,
                    value: l.id,
                  }))
                  }
                  onChange={(option) => {
                    setValues({
                      ...values,
                      role: option.value,
                    })
                    setRoleName(option.id)
                  }}
                  onBlur={handleBlur}
                  onFocus={()=>setFieldTouched('role',false)}
                />
                {touched.role && (
                  <p className="text-red-700 mb-2 error_msg">{errors.role}</p>
                )}
              <div className="mt-4"></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Email</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  name="email"
                  label="Email"
                  value={values.email}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={() => {
                    if (values.role == "parent") {
                      const name = getCustomerData(values.email);
                      console.log(name);
                    } else {
                      handleBlur();
                    }
                  }}
                  placeholder="Enter Email..."
                  onFocus={() => setFieldTouched('email', false)}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />

                {touched.email && (
                  <p className="text-red-700 error_msg">{errors.email}</p>
                )}
                <div className="mt-4"></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Name</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  name="name"
                  id='name'
                  label="Enter Name"
                  value={values.name}
                  autoComplete="off"
                  onBlur={handleBlur}
                  disabled={values.role == 'parent'}
                  onChange={(e) =>{

                    setValues({
                      ...values,
                      name:values.role == 'parent'?data.name:e.target.value})
                  }}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter Full Name..."
                  onFocus={()=>setFieldTouched('name',false)}
                />
                {touched.name && (
                  <p className="text-red-700 error_msg">
                    {errors.name}
                  </p>
                )}               
                {
                  values.role == 'manager' && (
                    <>
                    <div className="mt-4"></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Location</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>

                <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="locationId"
                  id="locationId"
                  isSearchable
                  loadingMessage="Getting Location..."
                  placeholder="Select a Location"
                  isMulti={true}
                  value={
                    values.locationId
                      ? values?.locationId?.map((l) => ({
                          value: l,
                          label: location.find((e) => e.id == l)
                            ?.name,
                        })): null
                  }
                  options={location?.map((l) => ({
                    ...l,
                    label: l.name,
                    value: l.id,
                  }))}
                  onChange={(option) => {
                    setValues({
                      ...values,
                      locationId: option.map((l) => l.value),
                    })
                  }}
                  onBlur={handleBlur}
                  onFocus={()=>setFieldTouched('locationId',false)}
                />
                {touched.locationId && (
                  <p className="text-red-700 mb-2 error_msg">{errors.locationId}</p>
                )}
                    </>
                  )
                }
                <div className='mt-4'>
                <Switch.Group
                  as="div"
                  className="flex items-center justify-between"
                >
                  <span className="flex flex-grow flex-col">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium text-gray-900"
                      passive
                    >
                      User Status
                    </Switch.Label>
                  </span>
                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={classNames(
                      enabled ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 toggleButton'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                      ? 'Add User'
                      : 'Update User'}
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
      />
      <ConfirmDialog
        setOpen={setInactiveStatusConfirmationDialog}
        open={inactiveStatusConfirmationDialog}
        onDelete={onStatusChange}
        description={'Do you really want to inactive the user?'}
        confirmationButtonText={'Inactive'}
      />
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Users</h1>
          </div>
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <Button
              onClick={() =>{
                setEnabled(false)
                setModal((prev) => ({ ...prev, state: true, type: 'add' }))
              }}
              className="flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mt-0 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add
            </Button>
          </div>
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) :
          <>
        <div className="absolute my-5 w-8/12 flex justify-end items-center text-right right-0 mr-2">
        <div
            className="rounded-full p-2 bg-indigo-900 text-white hover:bg-indigo-700 cursor-pointer duration-300"
            onClick={() => setOpenSlider(true)}
          >
            <FunnelIcon className="w-4 h-4" />
          </div>
      </div>
        {renderTable()}
          <nav
        className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div>
          <p className="text-sm text-gray-700">
            Showing{' '}
            <select
              onChange={(e) => {
                setLimit(e.target.value)
                setPage(0)
              }}
              value={limit}
            >
              <option value={count}>{count}</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
            </select>{' '}
            of <span className="font-medium">{count}</span> results
          </p>
        </div>
        <div className="flex items-center md:justify-end">
          <span
            onClick={goPrev}
            className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
          >
            Previous
          </span>
          <span className="text-sm">
            Page{' '}
            <input
              className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
              type="number"
              value={page + 1}
              onChange={(e) => setPage(e.target.value - 1)}
              max={totalPages}
              min="1"
            />
            <span className='ml-2'></span>/ {Math.ceil(totalPages)}
          </span>
          <span
            onClick={goNext}
            className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
          >
            Next
          </span>
        </div>
      </nav>
      </>}
      </div>
      <ColumnSlideOver
        open={openSlider}
        setOpen={setOpenSlider}
        title={title}
        description={description}
        data={columnFilter}
        handleClick={handleClick}
      />
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </Sidebar>
  )
}

export default UserPage

import Button from '../button'
import { Formik } from 'formik'
import { loginSchema } from '../../schema'
import logo from '../../assets/icons/logo.png'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { forgotPasswordSchema } from '../../schema'
import Modal from '../modals'
import app_api from '../../config/api'
import { qrCode } from '../../schema'
import Input from '../input'
import PinInput from 'react-pin-input';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    forgotEmail: '',
  },
}

const Login = ({
  loading,
  loader,
  authDataURL,
  genORCode,
  mfaMesage,
  statusCode,
  forgotError,
  error,
  login,
  forgotPasssword,
  success,
}) => {
  const navigate = useNavigate()
  const [moldKey, setmoldKey] = useState(localStorage.getItem('moldKey'))
  const [modal, setModal] = useState(initialModalState)
  const [dataURL, setDataURL] = useState('')
  const [hideGoogleAuth, setHideGoogleAuth] = useState(false)
  const [loginStatusCode, setLoginStatusCode] = useState()

  useEffect(() => {
    localStorage.getItem('moldKey') && navigate('/')
  }, [moldKey])

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const renderModal = () => {
    const { type, state, data } = modal
    const { forgotEmail } = data
    return (
      <Formik
        initialValues={{ forgotEmail: '' }}
        validationSchema={forgotPasswordSchema}
        enableReinitialize
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            title="Forgot Password"
            open={state}
            setOpen={() => {
              setModal((prev) => ({ ...prev, state: false }))
              cleanModalData()
            }}
          >
            <form noValidate>
              <div className="text-left mt-4">
                <div className="mb-4">
                  {forgotError !== null && (
                    <p className="text-red-700 error_msg error_text">
                      {forgotError}
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  {success !== null && <p className="success_msg">{success}</p>}
                </div>
                <div>
                  <label
                    htmlFor="forgotEmail"
                    className="block text-sm font-medium text-gray-700 loginFontSize"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="forgotEmail"
                      placeholder="Enter your Email"
                      name="forgotEmail"
                      type="forgotEmail"
                      autoComplete
                      required={true}
                      value={values.forgotEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm inputSize"
                    />
                    {touched.forgotEmail && (
                      <p className="text-red-700 error_msg">
                        {errors.forgotEmail}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <Button
                    disabled={isSubmitting}
                    loading={loader}
                    onClick={() => {
                      forgotPasssword(values)
                    }}
                  >
                    Request
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }
  return (
    <>
      {renderModal()}
      <div className="flex min-h-screen">
        <div className="flex flex-col mainFormBody">
          <div className=" h-100 w-full  ">
            <div>
              <img className="Signin_logo " src={logo} alt="Your Company" />
            </div>
            {statusCode == 0 && (
              <div className="mt-8">
                <div className="mt- flex ">
                  <Formik
                    initialValues={{ email: '', password: '' }}
                    validationSchema={loginSchema}
                    onSubmit={login}
                  >
                    {({
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      values,
                      errors,
                      touched,
                      isValid,
                    }) => (
                      <form
                        className="space-y-6 w-full SignInForm"
                        onSubmit={handleSubmit}
                        noValidate={true}
                      >
                        <h2 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 LoginMainText">
                          Log in
                        </h2>
                        <div
                          className="LoginSubText"
                          style={{ marginTop: '10px' }}
                        >
                          <span>Welcome back! Please enter your details.</span>
                        </div>
                        <div>
                          {error !== null && (
                            <p className="text-red-700 error_msg error_text">
                              {error}
                            </p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700 loginFontSize"
                          >
                            Email
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              placeholder="Enter your email"
                              name="email"
                              type="email"
                              autoComplete
                              required={true}
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm inputSize"
                            />
                            {touched.email && (
                              <p className="text-red-700 error_msg">
                                {errors.email}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="space-y-1">
                          <div>
                            <label
                              htmlFor="password"
                              className="block text-sm font-medium text-gray-700 loginFontSize"
                            >
                              Password
                            </label>
                            <div className="mt-1">
                              <input
                                id="password"
                                placeholder="*****"
                                name="password"
                                type="password"
                                autoComplete
                                required={true}
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm inputSize"
                              />
                              {touched.password && (
                                <p className="text-red-700 error_msg">
                                  {errors.password}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between -mt-1">
                          <a
                            className="text-indigo-400 font-semibold cursor-pointer hover:text-indigo-800 duration-500"
                            target='_blank'
                            href='/login-steps'
                          >
                            Need Help?
                          </a>
                          <a
                            className="text-red-400 font-semibold cursor-pointer hover:text-red-500"
                            onClick={() =>
                              setModal((prev) => ({
                                ...prev,
                                type: 'add',
                                state: true,
                              }))
                            }
                          >
                            Forgot Password
                          </a>
                        </div>
                        <div>
                          <Button
                            type="submit"
                            loading={loading}
                            className="text-center"
                          >
                            Sign In
                          </Button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            )}

            {/* MFA Authentication */}
            {statusCode == 202 && dataURL == '' && !hideGoogleAuth && (
              <div className="mt-8">
                <div className="mt- flex ">
                  <Formik
                    initialValues={{ mfa: false }}
                    validationSchema={null}
                    onSubmit={(values, { setSubmitting }) => {
                      let userData = JSON.parse(
                        localStorage.getItem('moldMFAKey')
                      )
                      if (values.mfa == true) {
                        app_api
                          .post('users/qrcode', { email: userData.email })
                          .then((res) => {
                            //setsubmiting false
                            setDataURL(res?.data)
                            setLoginStatusCode(res?.status)
                            setHideGoogleAuth(true)
                          })
                          .catch((err) => {
                            console.log(err)
                          })
                      }
                    }}
                  >
                    {({
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setValues,
                      values,
                      errors,
                      touched,
                      isValid,
                    }) => (
                      <form
                        className="space-y-6 w-full SignInForm"
                        onSubmit={handleSubmit}
                        noValidate={true}
                      >
                        <h2 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 LoginMainText">
                          Log in
                        </h2>
                        <div
                          className="LoginSubText"
                          style={{ marginTop: '10px' }}
                        >
                          <span>Welcome back! Please enter your details.</span>
                        </div>
                        <div>
                          {mfaMesage !== null && (
                            <p className="text-orange-700 warning_msg error_text">
                              {mfaMesage}
                            </p>
                          )}
                        </div>
                        <div className="flex justify-center text-center">
                          <img
                            src="https://www.vectorlogo.zone/logos/google/google-tile.svg"
                            className="w-20"
                          />
                        </div>
                        <div className="flex justify-start items-start w-full">
                          <input
                            type="checkbox"
                            name="mfa"
                            value={values.mfa}
                            className="w-3.5 h-3.5 mt-2"
                            onChange={(e) => {
                              setValues({
                                ...values,
                                mfa: e.target.checked,
                              })
                            }}
                          />
                          <p className="mt-0.5 ml-2">
                            Enable Multi-Factor Authentication{' '}
                          </p>
                        </div>
                        <div>
                          <Button
                            type="submit"
                            loading={loading}
                            className="text-center"
                          >
                            Generate QR Code
                          </Button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            )}

            {(statusCode == 201 || loginStatusCode == 201) &&
              (dataURL != '' || authDataURL != '') && (
                <div className="mt-8">
                  <div className="mt- flex ">
                    <Formik
                      initialValues={{ token: '' }}
                      validationSchema={qrCode}
                      onSubmit={(values, { setSubmitting }) => {
                        let email = JSON.parse(
                          localStorage.getItem('moldMFAKey')
                        ).email
                        console.log(values.token)
                        try{
                          if (values.token != '') {
                            app_api
                              .post('/users/verify', {
                                email: email,
                                token: values.token,
                              })
                              .then((res) => {
                                console.log(res)
                                if(res.data == false){
                                  toast.error('Incorrect Token')
                                }
                                else{
                                  setmoldKey(res.data)
                                  localStorage.setItem(
                                    'moldKey',
                                    JSON.stringify(res.data)
                                  )
                                  if (res.data.role === 'student'){
                                    navigate('/my-exams')
                                  }
                                  else{
                                    if(res.data.role == 'manager'){
                                      navigate('/leads')
                                    }
                                    else{
                                      if(res.data.role == 'teacher'){
                                        navigate('/teacher-schedule')
                                      }else{
                                        if(res.data.role == 'parent'){
                                          navigate('/schedule')
                                        }else{
                                          navigate('/leads')
                                        }
                                      }
                                    }
                                  }
                                }
                              })
                              .catch((err) => {
                                console.log(err)
                              })
                          } else {
                          }
                        }
                        catch(err){
                          console.log(err)
                        }
                        
                      }}
                    >
                      {({
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setValues,
                        values,
                        errors,
                        touched,
                        isValid,
                      }) => (
                        <form
                          className="space-y-6 w-full SignInForm"
                          onSubmit={handleSubmit}
                          noValidate={true}
                        >
                          <h2 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 LoginMainText text-center">
                            Two Factor Authentication
                          </h2>
                          <div
                            className="LoginSubText text-center"
                            style={{ marginTop: '10px' }}
                          >
                            <span>
                              To continue, please enter the 6-digit verification
                              code generated by your{' '}
                              <b>Google authenticator app</b>.
                            </span>
                          </div>
                          <div>
                            {mfaMesage == null && (
                              <p className="text-orange-700 warning_msg error_text">
                                {mfaMesage}
                              </p>
                            )}
                          </div>
                          {/* <div className="flex justify-center text-center">
                          <img
                            src={dataURL ? dataURL: authDataURL}
                            className="w-48"
                          />
                        </div> */}
                          <div className="flex justify-center items-center w-full">
                            {/* <Input
                              name="token"
                              type="number"
                              className="mb-1.5"
                              autoComplete="off"
                              onBlur={handleBlur}
                              placeholder="Enter code"
                              onChange={handleChange}
                              value={values.token}
                              // onChange={(event) =>
                              //   onModalHandleChange(event.target.name, event.target.value)
                              // }
                            /> */}

                            <PinInput 
                              length={6} 
                              initialValue=""
                              onChange={(event)=>{
                                setValues((prev) => ({
                                  ...prev,
                                  token: event
                                }))
                              }} 
                              type="numeric" 
                              inputMode="number"
                              style={{padding: '5px'}}  
                              inputStyle={{borderColor: 'gray', borderRadius: '10px', margin: '5px'}}
                              inputFocusStyle={{borderColor: 'blue'}}
                              onComplete={(value, index) => {}}
                              autoSelect={true}
                              regexCriteria={/^[0-9]*$/}
                              name='token'
                            />
                          </div>
                          {touched.token && (
                            <p className="text-red-700 mb-2 error_msg">
                              {errors.token}
                            </p>
                          )}
                          <div>
                            <Button
                              type="submit"
                              loading={loading}
                              className="text-center"
                            >
                              Proceed
                            </Button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
          </div>
          <div className="ml-4 absolute bottom-0">
            <span
              style={{
                color: '#667085',
                fontSize: '14px',
              }}
            >
              © NRICH 2023
            </span>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 md:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://ik.imagekit.io/bizwem110d/mold/Illustrations_jZRHXfAlAZ.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1671184411043"
            alt=""
          />
        </div>
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </>
  )
}

export default Login
